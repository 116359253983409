<template>
  <div class="container">
    <uiTopPageSection
      :src="backgroundImage"
      :bgPosition="{ x: '70%', y: '50%' }"
    >
      <div class="top-content-section">
        <h1 class="text_super_big">DEMIDOV products</h1>
        <uiAnimatedTexts
          :texts="[$t('products.topPage.p1'), $t('products.topPage.p2')]"
        />
      </div>
    </uiTopPageSection>
    <section class="top-page-anchors dark">
      <div class="top-page-anchors-items padding_small max_with">
        <div
          v-for="anchor in anchors"
          :key="anchor.anchor"
          @click="scrollMeTo(anchor.anchor)"
        >
          {{ $t(anchor.name) }}
        </div>
      </div>
    </section>
    <section ref="luxury" class="products-luxury light b_padding_medium">
      <div class="luxury-photos">
        <div
          class="luxury-photo"
          style="background-image: url('/products/luxury1.jpg')"
        ></div>
        <div
          class="luxury-photo desktop_show"
          style="background-image: url('/products/luxury2.jpg')"
        ></div>
        <div
          class="luxury-photo desktop_show"
          style="background-image: url('/products/luxury3.jpg')"
        ></div>
      </div>
      <div class="max_with">
        <p
          class="
            text_center text_bold text_uppercase text_medium
            t_margin_medium
          "
        >
          {{ $t("products.section6.p1") }}
        </p>
        <p class="text_small text_center padding_small">
          {{ $t("products.section6.p2") }}
        </p>
        <p class="text_small text_center padding_small">
          {{ $t("products.section6.p3") }}
        </p>
      </div>
    </section>
    <section
      ref="womens"
      class="products-womens dark t_padding_medium b_margin_small"
    >
      <div class="padding_small max_with">
        <p
          class="text_center text_bold text_uppercase text_medium padding_small"
        >
          {{ $t("products.section7.p1") }}
        </p>
        <p class="text_small text_center b_padding_small">
          {{ $t("products.section7.p2") }}
        </p>
      </div>
      <div class="products-women-images max_with">
        <img src="/products/women1.jpg" alt="" />
        <img src="/products/women2.jpg" alt="" />
      </div>
    </section>
    <section ref="carrying" class="products-carrying v_padding_medium max_with">
      <div class="padding_small">
        <p
          class="
            text_center text_bold text_uppercase text_medium
            b_margin_small
          "
        >
          {{ $t("products.section8.p1") }}
        </p>
        <p class="text_small text_center">{{ $t("products.section8.p2") }}</p>
      </div>
      <div class="products-carrying-items">
        <div
          class="carrying-img"
          style="background-image: url('/products/carrying.jpg')"
        ></div>
        <div class="padding_small t_margin_small">
          <p class="text_small">{{ $t("products.section8.p3") }}</p>
          <ui-targets-list :items="targetsListCarrying" />
        </div>
      </div>
      <div class="padding_small">
        <p class="text_medium text_center text_bold b_padding_small">
          {{ $t("products.section8.p4") }}
        </p>
        <div class="products-carrying-items">
          <div>
            <img v-scrollanimate="'right'" src="/products/comfort.svg" alt="" />
            <p v-scrollanimate="'left'" class="text_small text_center">
              {{ $t("products.section8.p5") }}
            </p>
          </div>
          <div>
            <img v-scrollanimate="'right'" src="/products/stealth.svg" alt="" />
            <p v-scrollanimate="'left'" class="text_small text_center">
              {{ $t("products.section8.p6") }}
            </p>
          </div>
          <div>
            <img v-scrollanimate="'right'" src="/products/package.svg" alt="" />
            <p v-scrollanimate="'left'" class="text_small text_center">
              {{ $t("products.section8.p7") }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section ref="bag" class="products-carrying-bag v_padding_medium max_with">
      <div
        class="bag-img desktop_hide"
        style="background-image: url('/products/slider-bag/bag.jpg')"
      ></div>
      <div class="vueper_slider desktop_show">
        <vueper-slides
          class="no-shadow"
          :slide-multiple="false"
          lazy-load-on-drag
          :visible-slides="3"
          :gap="3"
          :arrows="false"
          :bullets="true"
          :autoplay="true"
          :dragging-distance="70"
          :infinite="true"
          prevent-y-scroll
        >
          <vueper-slide
            v-for="(slide, i) in slidesBag"
            :key="i"
            :image="slide.image"
          >
            <template #loader>
              <i class="icon icon-loader spinning"></i>
              <span>Loading...</span>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
      <div class="padding_small">
        <div>
          <p
            class="
              text_center text_bold text_uppercase text_medium
              t_margin_small
            "
          >
            {{ $t("products.section9.p1") }}
          </p>
          <p class="text_small padding_small">
            {{ $t("products.section9.p2") }}
          </p>
        </div>
        <ui-targets-list :items="targetsListBag" />
      </div>
      <div class="video-link">
        <router-link
                tag="a"
                exact="true"
                class="video-link__href"
                :to="'/' + this.$i18n.locale + '/video_4'"
        >{{$t("messages.video")}} <font-awesome-icon :icon="['fab', 'youtube']" /></router-link>
      </div>
    </section>
    <section ref="torso" class="products-torso dark t_padding_medium">
      <div class="max_with">
        <p
          class="
            text_center text_bold text_uppercase text_medium
            t_margin_small
          "
        >
          {{ $t("products.section10.p1") }}
        </p>
        <p class="text_small text_center padding_small">
          {{ $t("products.section10.p2") }}
        </p>
        <p class="text_small text_center padding_small">
          {{ $t("products.section10.p3") }}
        </p>
      </div>
      <div class="products-torso-images max_with">
        <img src="/products/torso1.jpg" alt="" />
        <img src="/products/torso2.jpg" alt="" />
      </div>
    </section>
    <section ref="about" class="products_demidov section light">
      <img src="/products/roman.jpg" alt="" class="products_demidov_item" />
      <div class="products_demidov_text products_demidov_item">
        <h2
          v-scrollanimate="'bottom'"
          class="text_small text_bold v_padding_small"
        >
          {{ $t("products.section1.p1") }}
        </h2>
        <p v-scrollanimate="'bottom'" class="text_small text_italic">
          {{ $t("products.section1.p2-1") }}
          <span class="text_bold">{{ $t("products.section1.p2-2") }}</span>
        </p>
        <p
          v-scrollanimate="'bottom'"
          class="text_small text_italic b_margin_small"
        >
          {{ $t("products.section1.p3") }}
        </p>
        <p v-scrollanimate="'bottom'" class="text_small">
          {{ $t("products.section1.p4-1") }} <br />
          {{ $t("products.section1.p4-2") }}
        </p>
      </div>
    </section>
    <section class="products_demidov_gallery dark v_padding_medium">
      <div class="padding_medium max_with text_center">
        <h2
          v-scrollanimate="'bottom'"
          class="text_medium text_bold b_margin_small"
        >
          {{ $t("products.section2.p1") }}
        </h2>
        <p v-scrollanimate="'bottom'" class="text_small">
          {{ $t("products.section2.p2") }}
        </p>
      </div>
      <div class="v_padding_medium vueper_slider">
        <vueper-slides
          lazy-load-on-drag
          :bullets="true"
          :autoplay="true"
          :dragging-distance="70"
          prevent-y-scroll
          class="vueper_slide"
        >
          <vueper-slide
            v-for="(slide, i) in slides"
            :key="i"
            :image="slide.image"
          >
            <template #loader>
              <i class="icon icon-loader spinning"></i>
              <span>Loading...</span>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
    </section>
  </div>
</template>

<script>
import backgroundImage from "@/assets/products/products_bg_image.jpg";
import { VueperSlides, VueperSlide } from "vueperslides";
import uiTopPageSection from "../components/UI/uiTopPageSection";

import "vueperslides/dist/vueperslides.css";
import uiAnimatedTexts from "../components/UI/uiAnimatedTexts";
import uiTargetsList from "../components/UI/uiTargetsList";
import { gsap, Power2 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export default {
  name: "products",
  components: {
    VueperSlides,
    VueperSlide,
    uiTopPageSection,
    uiAnimatedTexts,
    uiTargetsList,
  },
  data() {
    return {
      backgroundImage,
      slides: [
        { image: "/products/slider/suits1.jpg" },
        { image: "/products/slider/suits2.jpg" },
        { image: "/products/slider/suits3.jpg" },
        { image: "/products/slider/suits4.jpg" },
        { image: "/products/slider/suits5.jpg" },
        { image: "/products/slider/suits6.jpg" },
      ],
      slidesBag: [
        { image: "/products/slider-bag/bag.jpg" },
        { image: "/products/slider-bag/bag1.jpg" },
        { image: "/products/slider-bag/bag2.jpg" },
        { image: "/products/slider-bag/bag3.jpg" },
        { image: "/products/slider-bag/bag4.jpg" },
        { image: "/products/slider-bag/bag5.jpg" },
      ],
      targetsListCarrying: [
        "products.section8.targetsList[0]",
        "products.section8.targetsList[1]",
        "products.section8.targetsList[2]",
      ],
      targetsListBag: [
        "products.section9.targetsList[0]",
        "products.section9.targetsList[1]",
        "products.section9.targetsList[2]",
        "products.section9.targetsList[3]",
        "products.section9.targetsList[4]",
      ],
      anchors: [
        { name: "products.anchors[0]", anchor: "luxury" },
        { name: "products.anchors[1]", anchor: "womens" },
        { name: "products.anchors[2]", anchor: "carrying" },
        { name: "products.anchors[3]", anchor: "bag" },
        { name: "products.anchors[4]", anchor: "torso" },
        { name: "products.anchors[5]", anchor: "about" },
      ],
    };
  },
  methods: {
    scrollMeTo(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;
      //window.scrollTo(0, top);
      gsap.to(window, {
        duration: 1,
        scrollTo: top - 100,
        ease: Power2.easeOut,
      });
    },
  },
};
</script>
<style lang="scss">
@import "../vars";
.products_demidov {
  img {
    width: 100%;
    border-radius: 5px;
  }
}
.products_demidov_gallery {
  color: white;
  .vueper_slide {
    .vueperslides__parallax-wrapper {
      height: 300px;
    }
  }
}

.products-luxury {
  .luxury-photos {
    display: flex;
    flex-direction: column;
    .luxury-photo {
      background-size: cover;
      background-position: center;
      height: 250px;
    }
  }
}
.products-womens {
  color: white;
  line-height: 1.5;
  .products-women-images {
    display: flex;
    flex-direction: column;
    img {
      border-radius: 5px;
      margin: 0 25px 25px 25px;
      max-width: 100%;
      height: auto;
    }
  }
}
.products-carrying {
  .carrying-img {
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100%;
  }
  .products-carrying-items {
    display: flex;
    flex-direction: column;
    div {
      padding: 0 10px 0 10px;
      display: flex;
      flex-direction: column;
      img {
        width: 60px;
        margin: 25px auto;
      }
    }
  }
}
.products-carrying-bag {
  .bag-img {
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100%;
  }
}
.products-torso {
  color: white;
  line-height: 1.5;
  .products-torso-images {
    display: flex;
    flex-direction: column;
    img {
      border-radius: 5px;
      margin: 0 25px 25px 25px;
      max-width: 100%;
      height: auto;
    }
  }
}
.top-page-anchors {
  position: sticky;
  top: 50px;
  z-index: 1000;
  .top-page-anchors-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: white;
    div {
      font-size: 14px;
      cursor: pointer;
      text-align: center;
    }
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .top-page-anchors {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(14, 14, 14, 0.7);
  }
}

@media (min-width: $small_w) {
  .products_demidov_gallery {
    .vueper_slide {
      .vueperslides__parallax-wrapper {
        height: 400px;
      }
    }
  }
  .products-luxury .luxury-photos .luxury-photo {
    height: 350px;
  }
  .products-carrying .carrying-img {
    height: 400px;
  }
  .products-carrying-bag .bag-img {
    height: 400px;
  }
  .top-page-anchors {
    .top-page-anchors-items {
      div {
        font-size: 18px;
      }
    }
  }
}
@media (min-width: $medium_w) {
  .products_demidov_gallery {
    .vueper_slide {
      .vueperslides__parallax-wrapper {
        height: 550px;
      }
    }
  }
  .products-luxury .luxury-photos .luxury-photo {
    height: 450px;
  }
  .products-carrying .carrying-img {
    height: 500px;
  }
  .products-carrying-bag .bag-img {
    height: 500px;
  }
  .products-womens .products-women-images img {
    margin: 0 auto 25px auto;
    max-width: 600px;
  }
  .products-torso .products-torso-images img {
    margin: 0 auto 25px auto;
    max-width: 600px;
  }
  .top-page-anchors {
    .top-page-anchors-items {
      padding: 10px 30px;
      div {
        font-size: 20px;
      }
    }
  }
}
@media (min-width: $large_w) {
  .products_demidov {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 10px;
      width: 50%;
      height: 50%;
      max-width: 800px;
    }
    div {
      padding-left: 40px;
    }
    .products_demidov_text {
      max-width: 800px;
    }
    .products_demidov_item {
      h2 {
        padding: 0 0 45px 0;
      }
    }
  }
  .products_demidov_gallery {
    .vueper_slider {
      width: 1000px;
      margin: auto;
    }
  }
  .products-luxury {
    padding-top: 20px;
    .luxury-photos {
      display: flex;
      flex-direction: row;
      gap: 20px;
      max-width: 2500px;
      margin: auto;
      .luxury-photo {
        height: 400px;
        flex-grow: 1;
      }
    }
  }
  .products-womens {
    padding-bottom: 100px;
    .products-women-images {
      display: flex;
      flex-direction: row;
    }
  }
  .products-carrying {
    .products-carrying-items {
      display: flex;
      flex-direction: row;
      margin-bottom: 50px;
      div {
        flex-grow: 1;
        flex-basis: 0;
      }
    }
  }
  .products-carrying-bag {
  }
  .products-torso {
    padding-bottom: 150px;
    .products-torso-images {
      display: flex;
      flex-direction: row;
    }
  }
  .top-page-anchors {
    top: 57px;
    .top-page-anchors-items {
      padding: 15px 30px;
      div {
        font-size: 20px;
      }
    }
  }
}
</style>
